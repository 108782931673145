<template>
    <v-container>

        <br>
        <br>

        <div :class="image">
            <v-img contain max-height="100" :src="ErrorImage" max-width="100" style="margin:auto;" />
        </div>

        <br>
        <br>

        <div :class="image">
            <div class="text-center">
                <label
                    style="font-family: Arial; font-style: normal; font-weight: 400; font-size: 20px; line-height: 20px; text-align: center; color: #232323; opacity: 0.75;">
                    Tu transacción no se pudo llevar a cabo
                </label>

                <br>
                <br>

                <label
                    style="font-family: Arial; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px; text-align: center; color: #232323; opacity: 0.75;">
                    Ningún cargo fue realizado en tu tarjeta.
                    Por favor intente nuevamente.
                </label>
            </div>
        </div>

        <br>
        <br>

        <div class="text-center">
            <v-btn style="  font: normal normal normal 18px/27px Arial;padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: #138468; box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                        border-radius: 12px; text-transform:inherit; color: white;  text-transform: inherit;  font-weight: bold !important;" elevation="0"
                @click="returnPage()">
                <v-icon color="white" dark left>
                    mdi-arrow-left
                </v-icon>
                Intentar nuevamente
            </v-btn>
        </div>

        <br>

    </v-container>
</template>

<script>
import ErrorImage from "@/assets/images/EXE.png";

export default {
    data() {
        return {
            image: null,
            ErrorImage,
            screen: window.screen.width,
            path: null,
        }
    },
    async mounted() {
        this.path = localStorage.getItem('path')
        if (this.screen > 800) {
            this.image = 'image'
        } else {
            this.image = 'image2'
        }
    },
    methods: {
        returnPage() {
            var keygen = localStorage.getItem('keygen');
            var external = localStorage.getItem('externalID');
            var typePlan = localStorage.getItem('typePlan');
            var typeCliente = ''
            switch (typePlan) {
                case 'soap-clientes-becs':
                    typeCliente = 'clientes'
                    break;
                case 'soap-clientes-becs':
                    typeCliente = 'general'
                    break;
                case 'soap-colaborador-becs':
                    typeCliente = 'colaborador'
                    break;
                // case 'soap-5990-2-becs':
                //     typeCliente = '5990'
                //     break;
                // case 'soap-5690-2-becs':
                //     typeCliente = '5690'
                //     break;
                case 'soap-5990-becs':
                    typeCliente = 'NqKa5MHGvHuMko5'
                    break;
                case 'soap-5690-becs':
                    typeCliente = 'toCSq1UEhGaZp4z'
                    break;
                case 'soap-5490-becs':
                    typeCliente = 'd6uaCqohiUk0UWW'
                    break;
                case 'soap-5190-becs':
                    typeCliente = 'MlHaYAwEjhNPs5j'
                    break;
                case 'soap-4990-becs':
                    typeCliente = 'vgqjYnJGvLfI6iO'
                    break;

                default:
                    break;
            }

            // window.location.pathname = '/' + this.path + '/inicio';
            window.location.href = process.env.VUE_APP_DOMINIO + typeCliente + '/resumen?keygen='+ keygen +'&external=' + external

        }
    }

}
</script>

<style lang="css" scoped>
.v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
}

.image {
    width: 30%;
    margin: auto;
}

.image2 {
    width: 100%;
    margin: auto;
}
</style>
