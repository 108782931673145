<template>
  <div class="containerStyle">
    <v-container>
      <!-- <div :class="bannerView">SEGURO OBLIGATORIO SOAP 2024</div> -->
      <div :class="bannerView"></div>
      <br><br>
      <br v-if="screen <= 800">

      <div v-if="e1 != 3 && e1 != 2" :class="cartBoxView">
        <v-btn :class="btnFindSoapView" id="volver_2" @click="redirectFindSoap()">CONSULTA TU SOAP</v-btn>
        <CarritoCompra id="home_2" :class="btnCartButtonView" :patentes="patentesCarrito" :e1="e1"></CarritoCompra>
      </div>

      <div v-else :class="cartBoxViewFinish">
        <v-btn :class="btnFindSoapView3" id="volver_5" @click="redirectFindSoap()">CONSULTA TU SOAP</v-btn>
      </div>

      <v-stepper alt-labels v-model="e1" elevation="0" :class="steps">
        <v-stepper-header :class="stepperHeaderView">

          <v-stepper-step :complete="e1 > 1" step="1"><b :class="stepLabelSelected">Revisa tus
              datos</b></v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2" class="stepLabel"><b
              :class="stepLabelSelected">Pagar</b></v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3" class="stepLabel"><b :class="stepLabelSelected">Recibe e imprime tu
              SOAP</b></v-stepper-step>

        </v-stepper-header>

        <v-stepper-items :class="stepperItem1">
          <v-stepper-content step="1">
            <div v-if="loadSoap" class="text-center">
              <br>
              <br>
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
              Cargando
            </div>

            <div v-else>

              <InitForm v-if="loadSoap == false" :carData="carData" :propietarioData="propietarioData"
                :newCar="newCar" />

            </div>

          </v-stepper-content>

          <v-stepper-content step="2">
            <div v-if="loading" class="text-center">
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
              Cargando
            </div>

            <SecForm v-else @getToken="getToken" />

          </v-stepper-content>

          <v-stepper-content step="3">
            <div v-if="loading || loadingFinal" class="text-center">
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br><br>
              Estamos procesando tu cotización. . .
            </div>

            <ThirdForm v-else-if="!loading && !loadingFinal" />

            <br>

          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <br><br>

      <div v-if="e1 == 2 && conditions == true && screen > 800" class="finalPayContainerDesktop">

        <div v-if="loadTokenPayment">

          <br>
          <br>

          <div style="display: flex; justify-content: center; align-items: center;">
            <b class="opcionPago">Paga tu Soap con tarjetas de crédito y débito BancoEstado</b>
          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-img contain :src="webPayLogo" style="max-width: 300px; height: 135px;" />
          </div>

          <div class="text-center">

            <br>
            <br>
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>

          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-btn disabled class="button1" id="no_3" @click="btnVolver()">Volver </v-btn>
            <br>
            <br>
            <br>

            <form :action="webPayUrl" method="POST" ref="form_payment">
              <input type="hidden" name="token_ws" :value="token" />
              <v-btn disabled class="button2" key="first" type="submit" value="Pagar" @click="obtenerToken"
                id="irAPagar_3">
                Ir a pagar
              </v-btn>
            </form>
          </div>

          <br>
          <br>

        </div>

        <div v-else>

          <br>
          <br>

          <div style="display: flex; justify-content: center; align-items: center;">
            <b class="opcionPago">Paga tu Soap con tarjetas de crédito y débito BancoEstado</b>
          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-img contain :src="webPayLogo" style="max-width: 300px; height: 135px;" />
          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-btn class="button1" id="no_3" @click="btnVolver()">Volver </v-btn>
            <br>
            <br>
            <br>

            <form :action="webPayUrl" method="POST" ref="form_payment">
              <input type="hidden" name="token_ws" :value="token" />
              <v-btn class="button2" key="first" type="submit" value="Pagar" @click="obtenerToken" id="irAPagar_3">
                Ir a pagar
              </v-btn>
            </form>
          </div>

          <br>
          <br>

        </div>

      </div>


      <div v-if="e1 == 2 && conditions == true && screen <= 800" class="finalPayContainerMovil">

        <div v-if="loadTokenPayment">

          <br>
          <br>

          <div
            style="display: flex; justify-content: center; align-items: center;text-align: center;max-width: 350px;margin:auto;">
            <b class="opcionPago2">Paga tu Soap con tarjetas de crédito y débito BancoEstado</b>
          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-img contain :src="webPayLogo"
              style="max-width: 300px; height: 135px;margin-top: 2%;margin-bottom: 3%;" />
          </div>

          <div class="text-center">

            <br>
            <br>
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>

          </div>

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <v-btn disabled class="button1Mobile" id="no_3" style=" margin-right: 30px" @click="btnVolver()">Volver
            </v-btn>

            <br>

            <form :action="webPayUrl" method="POST" ref="form_payment">
              <input type="hidden" name="token_ws" :value="token" />
              <v-btn disabled class="button2Mobile" id="irAPagar_3" key="first" type="submit" value="Pagar"
                @click="obtenerToken">
                Ir a pagar
              </v-btn>
            </form>

          </div>

          <br>
          <br>
        </div>

        <div v-else>

          <br>
          <br>

          <div
            style="display: flex; justify-content: center; align-items: center;text-align: center;max-width: 350px;margin:auto;">
            <b class="opcionPago2">Paga tu Soap con tarjetas de crédito y débito BancoEstado</b>
          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-img contain :src="webPayLogo"
              style="max-width: 300px; height: 135px;margin-top: 2%;margin-bottom: 3%;" />
          </div>

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <v-btn class="button1Mobile" id="no_3" style=" margin-right: 30px" @click="btnVolver()">Volver </v-btn>

            <br>

            <form :action="webPayUrl" method="POST" ref="form_payment">
              <input type="hidden" name="token_ws" :value="token" />
              <v-btn class="button2Mobile" id="irAPagar_3" key="first" type="submit" value="Pagar"
                @click="obtenerToken">
                Ir a pagar
              </v-btn>
            </form>

          </div>

          <br>
          <br>

        </div>

      </div>

    </v-container>

  </div>
</template>

<script>
import InitForm from "@/components/InitForm.vue";
import SecForm from "@/components/SecForm.vue";
import ThirdForm from "@/components/ThirdForm.vue";
import ShieldService from "../services/shield.service";
import CarritoCompra from "@/components/Carrito.vue";
import SoapService from "../services/soap.service";
import webPayLogo from "@/assets/images/webpay.png";
import swal from 'sweetalert';
import Vue from "vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {
  name: "Form",
  components: {
    InitForm,
    SecForm,
    ThirdForm,
    CarritoCompra
  },
  data() {
    return {
      typePlan: null,
      conditions: null,
      webPayLogo,
      token: null,
      bannerView: null,
      cartBoxView: null,
      btnFindSoapView: null,
      btnCartButtonView: null,
      stepperHeaderView: null,
      stepLabelSelected: null,
      btnFindSoapView3: null,
      cartBoxViewFinish: null,
      stepperItem1: null,
      switch: true,
      generaropt: null,
      loadSoap: true,
      keygen: null,
      e1: 1,
      steps: null,
      newCar: false,
      screen: window.screen.width,
      loading: false,
      loadingFinal: false,
      patenteData: {},
      patentes: [],
      patenteUtils: null,
      patentesCarrito: [],
      priceTotal: 0,
      typeCliente: null,
      querySuccessState: null,
      carData: {
        id: null,
        patente: null,
        patenteDv: null,
        year: null,
        type: null,
        marca: null,
        model: null,
        motor: null,
        price: null,
        externalProductId: null,
      },
      propietarioData: {
        rut: null,
        nombres: null,
        firtlastname: null,
        secondlastname: null,
        email: null,
        phoneNumber: null,
        propietario: true,
        rutPropietario: null,
        remarque: null,
        pricePuntos: null,
        campana: null,
      },
      planAuto: null,
      planCamioneta: null,
      planMoto: null,
      planArrastre: null,
      planBus: null,
      plans: {},
      planId: null,
      loadTokenPayment: false,
      loadPlans: true,
      webPayUrl: process.env.VUE_APP_WEBPAY_URL,
    };
  },
  async mounted() {
    this.carData.patente = localStorage.getItem('patente');
    this.propietarioData.email = localStorage.getItem('email');
    this.typePlan = localStorage.getItem('typePlan');
    const query = this.$route.query;
    if (query.process == "behind") {
      window.location.pathname = '/info'
    }
    if (this.typePlan) {
      var shieldRes = await ShieldService.getPlans(this.typePlan)
      if (shieldRes.status == 'ok') {
        this.plans = shieldRes.data[0]
        localStorage.setItem('planId', this.plans._id)
        this.loadPlans = false
      }
    }
    this.propietarioData.campana = process.env.VUE_APP_DOMINIO + window.location.pathname
    if (this.screen > 800) {
      this.steps = 'steps'
      this.bannerView = 'bannerView'
      this.cartBoxView = 'cartBoxView'
      this.btnFindSoapView = 'btnFindSoapView'
      this.btnCartButtonView = 'btnCartButtonView'
      this.stepperHeaderView = 'stepperHeaderView'
      this.stepLabelSelected = 'stepLabelSelected'
      this.btnFindSoapView3 = 'btnFindSoapView3'
      this.cartBoxViewFinish = 'cartBoxViewFinish'
      this.stepperItem1 = 'stepperItem1'
    } else if (this.screen <= 800) {
      this.steps = 'steps2'
      this.bannerView = 'bannerView2'
      this.cartBoxView = 'cartBoxView2'
      this.btnFindSoapView = 'btnFindSoapView2'
      this.btnCartButtonView = 'btnCartButtonView2'
      this.stepperHeaderView = 'stepperHeaderView2'
      this.stepLabelSelected = 'stepLabelSelected2'
      this.btnFindSoapView3 = 'btnFindSoapView3Mobile'
      this.cartBoxViewFinish = 'cartBoxViewFinish2'
      this.stepperItem1 = 'stepperItem1Mobile'
    }
    if (query) {
      if (query.success) {
        this.querySuccessState = 'ok'
        this.e1 = 3
      } else if (query.keygen) {
        this.keygen = localStorage.getItem('keygen');
        this.e1 = 2
      } else if (query.newCar == 'true') {
        this.newCar = true
        this.keygen = localStorage.getItem('keygen')
        await ShieldService.getCars(this.keygen).then((response) => {
          if (response.status == 'ok') {
            response.data.forEach(car => {
              this.patentesCarrito.push(car)
            });
          }
        })
      }

      if (this.carData.patente && this.propietarioData.email && this.typePlan && this.e1 == 1) {

        switch (this.typePlan) {
          case 'soap-clientes-becs':
            this.typeCliente = 'clientes'
            break;
          case 'soap-clientes-becs':
            this.typeCliente = 'general'
            break;
          case 'soap-colaborador-becs':
            this.typeCliente = 'colaborador'
            break;
          // case 'soap-5990-2-becs':
          //   this.typeCliente = '5990'
          //   break;
          // case 'soap-5690-2-becs':
          //   this.typeCliente = '5690'
          //   break;
          case 'soap-5990-becs':
            this.typeCliente = 'NqKa5MHGvHuMko5'
            break;
          case 'soap-5690-becs':
            this.typeCliente = 'toCSq1UEhGaZp4z'
            break;
          case 'soap-5490-becs':
            this.typeCliente = 'd6uaCqohiUk0UWW'
            break;
          case 'soap-5190-becs':
            this.typeCliente = 'MlHaYAwEjhNPs5j'
            break;
          case 'soap-4990-becs':
            this.typeCliente = 'vgqjYnJGvLfI6iO'
            break;

          default:
            break;
        }

        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('consultSoap')

        var soapRes = await SoapService.consultaSoap(this.carData.patente, token)

        if (soapRes.data == null) {
          swal({
            title: "Lo sentimos!",
            text: "No existen registros correspondientes a la patente ingresada",
            icon: "info",
            button: "Ok",
          }).then(() => {
            if (query.newCar == 'true') {
              window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio?newCar=true'
            } else {
              window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
            }
          });
        } else if (soapRes.status == 'ok') {
          this.carData.id = soapRes.data._id
          this.carData.year = soapRes.data.anio_fabricacion
          this.carData.patenteDv = soapRes.data.dv_ppu
          this.carData.type = soapRes.data.tipo_vehiculo
          this.carData.marca = soapRes.data.marca
          this.carData.model = soapRes.data.modelo
          this.carData.motor = soapRes.data.numero_motor

          this.propietarioData.rut = soapRes.data.rut + soapRes.data.dv

          var nombreCompleto = soapRes.data.nombre_razonsocial.split(' ')

          this.propietarioData.nombres = nombreCompleto[3] ? nombreCompleto[2] + ' ' + nombreCompleto[3] : nombreCompleto[2]
          this.propietarioData.firtlastname = nombreCompleto[0]
          this.propietarioData.secondlastname = nombreCompleto[1]
          this.loadSoap = false
        }

        switch (this.carData.type) {
          case 'AUTOMOVIL':
          case 'JEEP':
          case 'STATION WAGON':
          case 'LIMUSINA':
            this.carData.price = this.plans.soap.car.price;
            this.propietarioData.remarque = this.plans.soap.car.remarque;
            this.carData.externalProductId = this.plans.soap.car.externalProductId;
            break;
            
          case 'CAMIONETA':
          case 'FURGON':
            this.carData.price = this.plans.soap.camioneta.price;
            this.propietarioData.remarque = this.plans.soap.camioneta.remarque;
            this.carData.externalProductId = this.plans.soap.camioneta.externalProductId;
            break;

          case 'MOTO':
          case 'CUATRIMOTO':
          case 'MOTONETA':
            this.carData.price = this.plans.soap.moto.price;
            this.propietarioData.remarque = this.plans.soap.moto.remarque;
            this.carData.externalProductId = this.plans.soap.moto.externalProductId;
            break;

          case 'CARRO DE ARRASTRE':
          case 'CASA RODANTE MOTORIZADA':
            this.carData.price = this.plans.soap.arrastre.price;
            this.propietarioData.remarque = this.plans.soap.arrastre.remarque;
            this.carData.externalProductId = this.plans.soap.arrastre.externalProductId;
            break;

          case 'MINIBUS':
            this.carData.price = this.plans.soap.bus.price;
            this.propietarioData.remarque = this.plans.soap.bus.remarque;
            this.carData.externalProductId = this.plans.soap.bus.externalProductId;
            break;

          default:
            swal({
              title: "Tipo de vehículo no válido!",
              icon: "info",
              button: "Ok",
            }).then(() => {
              if (query.newCar == 'true') {
                window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio?newCar=true'
              } else {
                window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
              }
            });
            break;
        }

        // reCaptcha v3 token
        await this.$recaptchaLoaded()
        const tokenConsulta = await this.$recaptcha('consultaSoap');

        try {
          var consultaCarSoap = await ShieldService.createSoapConsulta(this.propietarioData, this.carData, tokenConsulta)
          console.log(consultaCarSoap)
        } catch (error) {
          console.log(error)
        }

      }
    }
  },
  methods: {
    async obtenerToken() {

      event.preventDefault();
      this.planId = localStorage.getItem('planId');
      this.loadTokenPayment = true;

      var webPayToken = await ShieldService.create(this.keygen, this.planId);
      if (webPayToken.status == 'ok') {
        this.token = webPayToken.data.token;
        setTimeout(() => {
          this.submitPago()
        }, 3000);
      } else {
        swal({
          title: "UPS! Algo salió mal.",
          text: "Vuelva a intentarlo nuevamente.",
          icon: "info",
          button: "Ok",
        }).then(() => {
          window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
        });
      }
    },
    async submitPago() {

      if (this.token && this.conditions) {
        console.log(this.token)
        console.log(this.conditions)
        this.$refs.form_payment.submit();
        return;
      } else {
        swal({
          icon: 'error',
          title: '¡Precaución!',
          text: 'Debes aceptar los términos y condiciones',
          timer: 2000,
          buttons: false,
        });
      }
    },
    async redirectFindSoap() {
      const consulta_1 = document.getElementById('consulta_1');
      const consulta_5 = document.getElementById('consulta_5');
      // window.location.href = process.env.VUE_APP_DOMINIO + 'consulta'
      this.$router.push({ path: "/consulta" });
    },
    btnVolver() {
      // this.$router.go(-1);
      window.location.href = process.env.VUE_APP_DOMINIO + 'clientes/datos'

      // if (this.newCar == true) {
      //     window.location.href = process.env.VUE_APP_DOMINIO + 'general/datos' + '?patente=' + this.carData.patente + '&email=' + this.propietarioData.email + '&newCar=' + this.newCar + '&type=soap-clientes-becs'
      //   } else {
      //     window.location.href = process.env.VUE_APP_DOMINIO + 'general/datos' + '?patente=' + this.carData.patente + '&email=' + this.propietarioData.email + '&type=soap-clientes-becs'
      //   }
    },
    getToken(elementos) {
      // this.token = elementos.token;
      // this.propietarioData.rutPropietario = elementos.rut;
      this.conditions = elementos.conditions;
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: #91A6E4;
  border-radius: 50%;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  /* Evitar que el contenido se visualice fuera del borde*/
}

.theme--light.v-stepper .v-stepper__label {
  text-align: center;
  font: Arial;
  font-size: 15px;
  letter-spacing: 0.23px;
  color: #262626;
  opacity: 1;
  font-size: 15px;
  width: 208px;
}

.v-input .v-label {
  text-align: left;
  font-size: 12px;
  font: Arial;
  letter-spacing: 0.18px;
  color: #2D387C;
  opacity: 1;
}

span.v-stepper__step__step.primary {
  width: 24px;
  height: 24px;
  opacity: 1;
  background: #4F74E3 !important;
  font-size: 18px;
  font-weight: bold;
}

.steps {
  width: 100%;
  margin-top: 5%;
}

.steps2 {
  min-width: 100%;
}

.containerStyle {
  background-color: #FFF;
  height: 80%;
  margin-bottom: 6.5%;
}

.containerStyle2 {
  background-color: #FFF;
  height: 80%;
  margin-bottom: 6%;
}

.bannerView {
  font-weight: bold;
  text-align: center;
  margin-top: -12%;
  font-size: 46px;
  color: #FFFFFF;
}

.bannerView2 {
  font-weight: bold;
  text-align: center;
  margin-top: -42%;
  font-size: 28px;
  color: #FFFFFF;
  margin-bottom: 9%;
}

.stepLabelSelected {
  font-size: 16px;
  font-weight: normal;
}

.stepLabelSelected2 {
  font-size: 12px;
  font-weight: normal;
  width: 80%;
}

.stepLabel {
  font-size: 20px;
}

.btnFindSoapView {
  width: 180px;
  height: 36px !important;
  background: #1D9C6F 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  margin-left: 42%;
  // margin-top: 2%;
  margin-bottom: -10.99%;
}

.btnFindSoapView2 {
  width: 200px;
  height: 36px !important;
  background: #1D9C6F 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  // margin-left: -105%;
  // margin-bottom: -49%;
}


.btnFindSoapView3 {
  width: 200px;
  height: 36px !important;
  background: #1D9C6F 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  // margin-left: 70%;
  // margin-top: 5%;
  // margin-bottom: -10.99%;
}

.btnFindSoapView3Mobile {
  width: 200px;
  height: 36px !important;
  background: #1D9C6F 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  // margin-left: -107%;
  // margin-top: 20%;
  font-size: 14px !important;
  // margin-bottom: -22%;
}

.cart {
  margin-left: 58%;
  margin-bottom: 1%;
}

.cartBoxView {
  margin-top: 7%;
  margin-left: 40%;
  margin-bottom: -3.6%;
  // background-color: greenyellow;
}

.cartBoxView2 {
  margin: auto;
  margin-top: 18%;
  display: flex;
  width: 80%;
  margin-bottom: 5%;
}

.finalPayContainer {
  background-color: #F3F3F3;
  width: 116.5%;
  margin-left: -8%;
  margin-right: auto;
  margin-bottom: -9%;
}

.btnCartButtonView2 {
  margin-left: 12%;
  margin-top: -3.9%;
}

.finalPayContainerMovil {
  background-color: #F3F3F3;
  width: 116.5%;
  margin-left: -8%;
  margin-right: auto;
  margin-bottom: -11%;
}

.finalPayContainerDesktop {
  background-color: #F3F3F3;
  width: 140%;
  margin-left: -19%;
  margin-bottom: -107px;
}

.imagesContainerMovile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-left: 16.5%;
}

.stepperHeaderView {
  box-shadow: none;
  margin: auto;
  width: 75%;
  margin-bottom: -2%;
}

.stepperHeaderView2 {
  box-shadow: none;
  margin: auto;
  width: 100%;
  margin-bottom: -8%;
}

.opcionPago {
  color: var(--AD-Primario-1, #2D387C);
  font-family: 'Helvetica';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.24px;
  text-transform: uppercase;
}

.opcionPago2 {
  color: var(--AD-Primario-1, #2D387C);
  font-family: 'Helvetica';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.24px;
  text-transform: uppercase;
}

.button1 {
  width: 270px;
  height: 50px !important;
  background-color: #2D387C !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: -7%;
  font-weight: bold;
}

.button1Mobile {
  width: 270px;
  height: 50px !important;
  background-color: #2D387C !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: 5%;
  font-weight: bold;
  margin-bottom: 2%;
}

.button2 {
  width: 270px;
  height: 50px !important;
  background-color: #EE811E !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: 35%;
  font-weight: bold;
}

.button2Mobile {
  width: 270px;
  height: 50px !important;
  background-color: #EE811E !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: -2%;
  font-weight: bold;
}

.v-stepper:not(.v-stepper--vertical) .v-stepper__label {
  display: contents !important;
}

.stepperItem1 {
  margin-bottom: -1%;
}

.stepperItem1Mobile {
  margin-bottom: -8%;
}

.cartBoxViewFinish {
  margin-top: 10%;
  margin-bottom: -3.7%;
  margin-left: 80.9%;
}

.cartBoxViewFinish2 {
  width: 90%;
  margin-top: 19%;
  margin-bottom: 5.5%;
  margin-left: auto;
}
</style>